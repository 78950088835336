// GBDMessage.js
import Vue from 'vue';
import ToastManager from './ToastManager.vue';

const ToastConstructor = Vue.extend(ToastManager);

let instance;

const initInstance = () => {
    instance = new ToastConstructor({
        el: document.createElement('div')
    });
    document.body.appendChild(instance.$el);
};

const GBDMessage = ({ message, duration = 3000, type = 'primary' }) => {
    if (!instance) {
        initInstance();
    }
    instance.addToast(message, duration, type);
};

export { GBDMessage };
