import store from "@/store";

let pendingRequests = new Map();

export function addPendingRequest(url, canceler, determineComponent) {
    // 假设请求配置中有一个字段determineComponent指定使用哪个加载组件
    if (determineComponent === 'full') {
        store.commit('setLoadingFull', true);
    } else if (determineComponent === 'part') {
        store.commit('setLoadingPart', true);
    }
    pendingRequests.set(url, canceler);
}

export function removePendingRequest(url) {
    pendingRequests.delete(url);
    store.commit('setLoadingFull', false);
    store.commit('setLoadingPart', false);
}

export function cancelAllRequests() {
    pendingRequests.forEach(cancel => cancel());
    pendingRequests.clear();
    if (pendingRequests.size == 0) {
        store.commit('setLoadingFull', false);
        store.commit('setLoadingPart', false);
    }
}
