<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";
import { Notify } from 'vant';
export default {
  data() {
    return {
      user: {
        password: "",
        rolename: "customer",
      },
    };
  },
  mounted() {
    if (Cookies.get("customer-token")) {
      this.$router.push({ path: "/index" });
    }
  },
  methods: {
    ...mapActions(["loginHandle"]),
    login() {
      // this.$router.push({ path: "/index" });
      this.loginHandle(this.user).then((res) => { });
    }
  },
};
</script>

<template>
  <div class="container">
    <div class="box">
      <div class="logo">
        <img src="./../../assets/logo.png" alt="" />
      </div>
      <div class="text">Autobooking Portal</div>
      <div class="text">Please enter the serial id to log in</div>
      <input type="text" v-model="user.password" placeholder="Unique serial id" class="input" />
      <div class="btn" @click="login">Login</div>
    </div>
  </div>
</template>

<style scoped lang="less">
.container {
  width: 100vw;
  height: 100vh;
  background-color: #f0f3f4;
  overflow: auto;
}

.box {
  margin: 200px auto;
  text-align: center;
  width: 304px;

  .logo {
    img {
      width: 100px;
    }
  }

  .text {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: #676a6c;
    margin: 16px 2px;
    font-weight: 700;
  }

  .input {
    display: block;
    border-color: #cfdadd;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfdadd;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .input:focus {
    outline: 1px solid #12a182;
  }

  .btn {
    background-color: #12a182;
    color: #f0f3f4;
    margin-top: 16px;
    padding: 6px 0;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
  }
}
</style>