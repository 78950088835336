// src/utils/configLoader.js
import axios from 'axios';

let config = null;

export async function loadConfig() {
    if (!config) {
        try {
            // 根据环境变量判断配置加载方式
            if (process.env.NODE_ENV === 'development') {
                // 开发环境使用环境变量
                config = {
                    apiUrl: process.env.VUE_APP_API_URL
                };
            } else {
                // 生产环境从配置文件加载
                const response = await axios.get('/admin/config.json');
                config = response.data;

                // config = {
                //     apiUrl: process.env.VUE_APP_API_URL
                // };
            }
        } catch (error) {
            console.error('Failed to load config:', error);
            config = {}; // 返回一个空对象作为默认配置
        }
    }
    return config;
}

export function getConfig() {
    return config;
}
