<!-- CustomToast.vue -->
<template>
    <transition name="fade">
        <div class="custom-toast" :class="typeClass" v-if="visible">
            {{ message }}
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: true
        },
        duration: {
            type: Number,
            default: 3000
        },
        type: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            visible: true
        };
    },
    computed: {
        typeClass() {
            return `toast-${this.type}`;
        }
    },
    mounted() {
        setTimeout(() => {
            this.visible = false;
            setTimeout(() => {
                this.$emit('close');
            }, 300); // 给渐出动画留出时间
        }, this.duration);
    }
};
</script>

<style scoped>
.custom-toast {
    color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.toast-primary {
    background-color: #000;
}

.toast-success {
    background-color: #4caf50;
}

.toast-warning {
    background-color: #ff9800;
}

.toast-error {
    background-color: #f44336;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
