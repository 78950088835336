import axios from 'axios';
import { Message, MessageBox } from 'element-ui'; // 假设你使用 Element UI 进行错误提示  
import store from '@/store'; // 假设你使用 Vuex 进行状态管理  
import Cookies from 'js-cookie';
import router from '@/router';
import { addPendingRequest, removePendingRequest } from './requestCanceller';
import { loadConfig, getConfig } from './configLoader';

// 创建 axios 实例  
const request = axios.create({
    baseURL: "", // url = base url + request url  
    // baseURL: "http://106.13.21.38", // url = base url + request url  
    // timeout: 120000 // 请求超时时间  
});

// 加载配置文件并设置axios实例的baseURL
loadConfig().then(config => {
    request.defaults.baseURL = config.apiUrl;
});

// 请求拦截器  
request.interceptors.request.use(
    config => {
        if (Cookies.get('customer-token')) {
            // config.headers['Authorization'] = `Bearer ${store.getters.token}`;
            config.headers['Authorization'] = 'Bearer ' + Cookies.get('customer-token');
        }

        let cancel;
        config.cancelToken = new axios.CancelToken(c => cancel = c);
        addPendingRequest(config.url, cancel, config.determineComponent);

        return config;
    },
    error => {
        // 请求错误做些什么  
        // console.log(error); // for debug  
        // return Promise.reject(error);
        store.commit('setLoadingFull', false);
        store.commit('setLoadingPart', false);
        return Promise.reject(error);
    }
);

// 响应拦截器  
request.interceptors.response.use(
    response => {
        removePendingRequest(response.config.url);
        return response.data;
    },
    error => {
        // 错误处理逻辑
        if (error.config && error.config.url) {
            removePendingRequest(error.config.url);
        }
        // 响应错误做些什么  
        if (error.response.status) {
            switch (error.response.status) {
                // 根据不同的状态码进行不同的处理  
                case 401:
                    Cookies.remove('customer-token')
                    Cookies.remove('customer-country')
                    router.push({ path: '/' })
                    break
                default:
                    Message({
                        message: error.response.data.msg,
                        type: 'error',
                        duration: 3 * 1000
                    });
            }
            return Promise.reject(error.response);
        }
        return Promise.reject(error);
    }
);

export default request;